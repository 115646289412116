<template>
  <div>
    <el-form class="query-form searchForm" size="small" ref="searchForm" :model="searchForm" label-width="100px" @keyup.enter.native="search(1)">
      <el-form-item label="关键字" prop="resourceName">
        <el-input v-model.tirm="searchForm.resourceName"
                  placeholder="请输入资源名称、资源编号"
                  maxlength="50" clearable></el-input>
      </el-form-item>
      <el-form-item prop="resourceType" label="资源类型：">
        <el-select v-model="searchForm.resourceType" clearable placeholder="请选择资源类型" class="w100i">
          <el-option v-for="item in $dictUtils.getDictList('resource_type')" :key="item.label"
                     :label="item.label"
                     :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="createId" label="下载人：">
        <el-select v-model="searchForm.createId" filterable placeholder="请选择" clearable class="w100i">
          <el-option v-for="item in userList" :key="item.id" :label="item.name"
                     :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="文件名" prop="fileName">
        <el-input v-model.tirm="searchForm.fileName"
                  placeholder="请输入文件名称"
                  maxlength="100" clearable></el-input>
      </el-form-item>
      <el-form-item prop="createBeginDate" label="下载时间：" class="dateBox">
        <el-date-picker
            clearable
            v-model="createDate"
            type="datetimerange"
            range-separator="至"
            value-format="yyyy-MM-dd HH:mm:ss"
            start-placeholder="开始日期"
            end-placeholder="结束日期" class="w100i">
        </el-date-picker>
      </el-form-item>
      <el-form-item label-width="20px">
        <el-button type="primary" @click="search(1)" icon="el-icon-search">查询</el-button>
        <el-button @click="reset()" icon="el-icon-refresh-right">重置</el-button>
      </el-form-item>
      <div class="f_r">
        <el-button size="small" v-show="hasPermission('record:log:export')" @click="exportDataAll()"><i class="icon-piliangdaochu iconfont buIcon"/>全部导出</el-button>
        <el-button size="small" v-show="hasPermission('record:log:export')" @click="exportData()"><i class="icon-piliangdaochu iconfont buIcon"/>本页导出</el-button>
      </div>
    </el-form>
    <div class="bg-white">
      <el-table :data="dataList" ref="multipleTable" v-loading="loading" size="small" height="calc(100vh - 430px)" class="table">
        <el-table-column prop="resourceId" show-overflow-tooltip label="资源编号">
        </el-table-column>
        <el-table-column prop="resourceName" show-overflow-tooltip label="资源名称">
        </el-table-column>
        <el-table-column prop="resourceType" show-overflow-tooltip label="资源类型">
          <template slot-scope="scope">
            {{ $dictUtils.getDictLabel("resource_type", scope.row.resourceType, '') }}
          </template>
        </el-table-column>
        <el-table-column prop="fileName" show-overflow-tooltip label="文件名">
        </el-table-column>
        <el-table-column prop="operationRes" show-overflow-tooltip label="操作结果">
        </el-table-column>
        <el-table-column prop="createUserName" show-overflow-tooltip label="下载人"></el-table-column>
        <el-table-column prop="createDate" show-overflow-tooltip label="下载时间"></el-table-column>
        <el-table-column prop="createIp" show-overflow-tooltip label="下载人IP"></el-table-column>
      </el-table>
      <el-pagination
          @size-change="sizeChangeHandle"
          @current-change="currentChangeHandle"
          :current-page="searchForm.current"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="searchForm.size"
          :total="total"
          background
          layout="total, sizes, prev, pager, next, jumper">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {

  data() {
    return {
      searchForm: {
        resourceName: "",
        resourceType: "",
        createId: "",
        fileName: "",
        createBeginDate: '',
        createEndDate: '',
        current: 1,
        size: 10,
      },
      createDate: [],
      userList: [],
      dataList: [],
      total: 0,
      loading: false,
    }
  },
  mounted() {
    this.getUserList()
    this.search(1)
  },
  methods: {
    // 获取数据列表
    search(type) {
      if (type == 1) {
        this.searchForm.current = 1
      }
      this.loading = true
      let p = JSON.parse(JSON.stringify(this.searchForm))
      p.createBeginDate = this.createDate.length ? this.createDate[0] : ''
      p.createEndDate = this.createDate.length ? this.createDate[1] : ''
      this.$axios(this.api.digital.resourceFileDownloadLog, p, 'post').then(res => {
        if (res.status) {
          this.dataList = res.data.records
          this.total = parseInt(res.data.total)
        } else {
          this.$message.error('查询失败');
        }
        this.loading = false
      })
    },

    // 获取用户列表
    getUserList() {
      this.$axios(this.api.auth.sysuserQueryAllList, {
        current: 1,
        size: 9999,
        orderBy: '',
        name: '',
        loginName: '',
      }, 'get').then(data => {
        if (data && data.status) {
          this.userList = data.data.records
        }
      })
    },

    //重置
    reset() {
      this.createDate = []
      this.$refs.searchForm.resetFields();
      this.search(1)
    },

    // 导出
    exportData() {
      let idList = this.dataList.map(item => {
        return item.id
      })
      this.exportExcel(this.api.record.exportResourceFileDownloadLog, idList, '资源下载操作日志', 'post')
    },

    // 导出全部
    exportDataAll() {
      const h = this.$createElement;
      this.$msgbox({
        title: '温馨提示',
        message: h('p', null, [
          h('p', {style: 'color: #000000; margin-bottom: 5px'}, '确定导出所筛选后日志数据？'),
          h('p', {style: 'color: #999999; font-size: 12px'}, '注意：当所筛选的日志数据大于20000条时，只导出最新的20000条日志')
        ]),
        showCancelButton: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(() => {
        let p = JSON.parse(JSON.stringify(this.searchForm))
        p.createBeginDate = this.createDate.length ? this.createDate[0] : ''
        p.createEndDate = this.createDate.length ? this.createDate[1] : ''
        this.exportExcel(this.api.record.exportResourceFileDownloadLogAll, p, '资源下载操作日志', 'post')
      })
    },

    // 每页数
    sizeChangeHandle(val) {
      this.searchForm.size = val
      this.searchForm.current = 1
      this.search()
    },
    // 当前页
    currentChangeHandle(val) {
      this.searchForm.current = val
      this.search()
    },
  }
}
</script>
<style scoped lang="scss">
.searchForm {
  .el-form-item--small.el-form-item {
    display: inline-block;
    width: 24.5%;
  }

  .dateBox.el-form-item--small.el-form-item {
    display: inline-block;
    width: 38%;
  }
}


</style>
